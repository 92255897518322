import { LanguageSwitch, toast, ToastContainer } from '@boxine/tonies-ui'
import { useId } from '@reach/auto-id'
import * as Sentry from '@sentry/react'
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import GenericErrorPage from './components/error-pages/GenericErrorPage'
import { MyToniesFooter } from './components/Footer'
import { Head } from './components/head'
import MyToniesHeader from './components/MyToniesHeader'
import { PromotionBanner } from './components/PromotionBanner'
import RedirectExternal from './components/RedirectExternal/RedirectExternal'
import { AnonymousRoute } from './components/routes'
import { ProtectedRoute } from './components/routes/ProtectedRoute'
import { FeaturePreview } from './components/Skeletons/feature-page'
import { Aside, SupportWidget } from './components/SupportWidget'
import { VersionUpdateNotification } from './components/VersionUpdateNotification'
import AcceptInvitation from './features/accept-invitation'
import { ContentToniePage } from './features/ContentToniePage'
import { FreeContentLandingPage } from './features/FreeContentLandingPage'
import Home from './features/home'
import InvitationDetails from './features/invitation-details'
import { NoMatch } from './features/NoMatch'
import TonieResetPage from './features/TonieResetPage'
import { Welcome } from './features/Welcome'
import useCookie from './hooks/useCookie'
import { useSupportedLanguages } from './hooks/useSupportedLanguages'
import {
  addRequestInterceptor,
  addResponseInterceptor,
  onNetworkError,
  onUnauthorizedError,
} from './http.service'
import { useAuth } from './providers/auth'
import { useFlag } from './providers/feature-flags'
import { useHeaderFooterSwitch } from './providers/HeaderFooterSwitch'
import { useUserProfile } from './providers/user-profile'
import { getDomain, isAutomatedTest } from './utils/functions/functions'
import { useDisableSentry } from './utils/sentry'
import { useLucidDreamFlag } from './hooks/useLucidDreamFlag'
import { RedirectLucidDream } from './features/LucidDream/RedirectLucidDream'

// TODO Replace any by better types once setup/index.js is migrated to TypeScript
const Setup = lazy<any>(
  () => import(/* webpackChunkName: "setup" */ './features/setup')
)

function ARDLandingPage() {
  const { t } = useTranslation()
  toast(t('ard-landing-page:redirectMessage'), 'success', {
    toastId: 'ard-landing-page-redirect-message',
  })
  return <Redirect to="/audio-library/free-for-you" />
}
const AudioLibraryPaymentPage = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-library-payment-page" */ './features/AudioLibraryPaymentPage'
    )
)
const UserProfile = lazy(
  () => import(/* webpackChunkName: "user-profile" */ './features/user-profile')
)
const AudioCandyAssign = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-candy-assign" */ './features/AudioCandyAssign'
    )
)
const AudioCandyDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-candy-detail-page" */ './features/AudioCandyDetailPage'
    )
)
const AudioAssignTonie = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-assign-tonie" */ './features/AudioAssignTonie'
    )
)
const AudioLibraryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-library-page" */ './features/AudioLibraryPage'
    )
)
const AudioLibraryDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "audio-library-detail-page" */ './features/AudioLibraryDetailPage'
    )
)

const MemberDetails = lazy(
  () =>
    import(/* webpackChunkName: "member-details" */ './features/member-details')
)
const Members = lazy(
  () => import(/* webpackChunkName: "members" */ './features/members')
)
const TonieboxDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "toniebox-details" */ './features/toniebox-details'
    )
)
const TonieboxesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "tonieboxes-page" */ './features/TonieboxesPage'
    )
)
const TonieRefreshPage = lazy(
  () =>
    import(
      /* webpackChunkName: "tonie-refresh" */ './features/TonieRefreshPage'
    )
)
const CreativeToniePage = lazy(
  () =>
    import(
      /* webpackChunkName: "creative-tonie-page" */ './features/CreativeToniePage'
    )
)
const ContentTonieDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "content-tonie-detail-page" */ './features/ContentTonieDetailPage'
    )
)
const ContentTonieEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "content-tonie-edit-page" */ './features/ContentTonieEditPage'
    )
)

const Legals = lazy(
  () =>
    import(
      /* webpackChunkName: "legal-pages" */
      './features/Legals'
    )
)

const CreativeTonieDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CreativeTonieDetailPage" */
      './features/CreativeTonieDetailPage'
    )
)

const ByeBye = lazy(
  () =>
    import(
      /* webpackChunkName: "byebye" */
      './features/bye-bye'
    )
)

const LucidDream = lazy(async () => {
  const chunk = await import(
    /* webpackChunkName: "LucidDream" */
    './features/LucidDream'
  )

  return {
    default: chunk.LucidDream,
  }
})

const Wrapper = styled.div<{ isNewCreativeToniePdP?: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${props =>
    props.isNewCreativeToniePdP ? props.theme.colors.white : ''};
`

// This component is required to be a child of the <Router> wrapper to receive the correct location
function CloseLanguageSwitchOnNavigation({
  onNavigation,
}: {
  onNavigation: () => void
}) {
  const location = useLocation()

  useEffect(() => {
    onNavigation()
  }, [location, onNavigation])

  return null
}

const App = () => {
  const [lastRequestStatus, setLastRequestStatus] = useState(200)
  const { updateUser, user } = useUserProfile()
  const { resetPassword, login, logout, register, isChecked, isAuthenticated } =
    useAuth()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isWebview = params.get('webview') === 'app'
  const { showFooter, showHeader } = useHeaderFooterSwitch()
  const isSentryDisabled = useFlag('disableSentry')
  const { isEnabled: isLucidDreamEnabled } = useLucidDreamFlag()
  const { i18n, t } = useTranslation('default')
  const isSupportWidgetEnabled = useFlag('supportWidget')

  useDisableSentry(isSentryDisabled)

  useEffect(() => {
    let errorCount = 0

    function update() {
      onNetworkError(error => {
        // Skip first HTTP error messages in setup feedback
        if (
          error.config.method.toUpperCase() === 'GET' &&
          location.search.startsWith('?r') &&
          errorCount < 5
        ) {
          errorCount++
          return
        }

        Sentry.withScope(scope => {
          scope.setTag('feature', 'App#onNetworkError')

          scope.setExtra('request', error.request)
          scope.setExtra('response', error.response)
          scope.setExtra('config', error.config)

          // we have too many badly handled network errors
          // downgrade them for now, for a little less noise
          scope.setLevel('warning')

          Sentry.captureException(error)
        })

        // Deactivates the general network error toast for the ??? landing page to
        // avoid redundant notification to the user (TOC-3286)
        if (!location.search.startsWith('???')) {
          toast(t('default:TOASTNetworkError', { errorCode: 121 }), 'error', {
            toastId: 'toast-network-error',
          })
        }
      })

      // Global handling of any 401 responses
      onUnauthorizedError(() => {
        logout(new URL(window.location.pathname, window.location.origin))
      })

      addRequestInterceptor(config => {
        try {
          const jwt = localStorage.getItem('authorization')
          if (jwt) {
            const headers = { Authorization: `Bearer ${jwt}` }
            Object.assign(config, { headers })
          }
          // swallow localStorage errors
        } catch (e) {}

        return config
      })

      addResponseInterceptor(
        response => {
          errorCount = 0
          return response
        },
        error => {
          const status = error.response?.status
          setLastRequestStatus(status)
          return Promise.reject(error)
        }
      )
    }

    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Does not have any effect because it is just fired at "mount"
  // `location` does not change even if it is updated
  // while this is used outside <Router>
  useEffect(() => {
    setLastRequestStatus(200)
  }, [location])

  // Event Listener to prevent input going out of viewport when switching from portrait to landscape mode
  useEffect(() => {
    function keepInputInFocus() {
      // if device is in landscape mode, run logic
      if (window.orientation === 90 || window.orientation === -90) {
        const element = document.activeElement
        if (
          element &&
          element !== document.body &&
          element.localName === 'input'
        ) {
          element.scrollIntoView()
        }
      }
    }

    window.addEventListener('orientationchange', keepInputInFocus)

    return () => {
      window.removeEventListener('orientationchange', keepInputInFocus)
    }
  }, [])

  const [isLanguageSwitchOpen, setIsLanguageSwitchOpen] = useState(false)
  const wasTriggeredByKeyboardRef = useRef(false)
  const idLanguageSwitch = useId()
  const supportedLanguages = useSupportedLanguages()

  // When the user isn't logged in they can still change the language.
  // This flag will be set when the user does so in a logged out state. Once
  // they sign in they'll expect the language to remain the same, despite there
  // being a potentially different language defined in the user's profile. In
  // that case the value in the profile will be overwritten.
  const [userDecidedLanguage, setUserDecidedLanguage] = useCookie(
    'userDecidedLanguage',
    'false',
    {
      domain: getDomain(),
      expires: 7,
      path: '/',
    }
  )

  async function changeLanguage(languageCode: string) {
    // User made decision --> Override assumed language derived from browser settings
    if (!isAuthenticated) {
      setUserDecidedLanguage('true')
    }

    // Note: This persists the language choice in a cookie (is configured to serve as the cache for react-i18next)
    await i18n.changeLanguage(languageCode)

    // Change language if user is already logged in
    if (
      isAuthenticated &&
      user !== null &&
      i18n.language !== user.unicodeLocale
    ) {
      try {
        await updateUser({ unicodeLocale: i18n.language })

        // Close overlay only if response was successful (avoids issues with non-reactive user profile)
        setIsLanguageSwitchOpen(false)
      } catch {
        toast(t('default:TOASTSomethingWentWrong'), 'error', {
          toastId: 'change-language-failure',
        })
      }
    } else {
      setIsLanguageSwitchOpen(false)
    }
  }

  useEffect(() => {
    async function run() {
      if (
        userDecidedLanguage === 'false' &&
        user &&
        i18n.language !== user.unicodeLocale
      ) {
        await i18n.changeLanguage(user.unicodeLocale)
      }

      if (
        userDecidedLanguage === 'true' &&
        user &&
        i18n.language !== user.unicodeLocale
      ) {
        try {
          await updateUser({ unicodeLocale: i18n.language })
        } catch {
          toast(t('default:TOASTSomethingWentWrong'), 'error', {
            toastId: 'change-language-failure',
          })
        }
      } else if (
        userDecidedLanguage === 'true' &&
        user &&
        i18n.language === user.unicodeLocale
      ) {
        setUserDecidedLanguage('false')
      }
    }

    run()
  }, [
    i18n,
    i18n.language,
    setUserDecidedLanguage,
    t,
    updateUser,
    user,
    userDecidedLanguage,
  ])

  const closeLanguageSwitch = useCallback(() => {
    setIsLanguageSwitchOpen(false)
  }, [setIsLanguageSwitchOpen])
  const isCreativeTonieDetailPage =
    location.pathname.includes('/creative-tonies')

  const isCreativeTonieDetailPageAssignOrEditView = Boolean(
    isCreativeTonieDetailPage &&
      (location.pathname.includes('/assign') ||
        location.pathname.includes('/edit'))
  )

  /**
   * Initialize Google Tag Manager V4 + UserCentrics
   *
   * The TagManager itself only tracks when the user opts-in, so we load it immediately
   */
  const gtmDomain = process.env.REACT_APP_GTM_DOMAIN
  const gtmId = process.env.REACT_APP_GTM_ID || ''
  const gtmAuth = process.env.REACT_APP_GTM_AUTH
  const gtmPreview = process.env.REACT_APP_GTM_PREVIEW

  useEffect(() => {
    if (!isAutomatedTest()) {
      /**
       * TODO
       * Move 1. and 2. to GTM in coordination with Marc
       */
      /**
       * https://docs.usercentrics.com/#/consent-mode
       */
      const scripts = [
        // 1. Google Consent Mode Defaults
        `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}gtag("consent","default",{ad_user_data:"denied",ad_personalization:"denied",ad_storage:"denied",analytics_storage:"denied",wait_for_update:2e3});gtag("set","ads_data_redaction",true);`,
        // 2. Usercentrics Consent Listener
        `window.addEventListener("ucEvent",(function(e){if(e.detail&&e.detail.event=="consent_status"){var ucAnalyticsService="Google Analytics 4";var ucAdService="Google Ads";if(e.detail.hasOwnProperty(ucAnalyticsService)&&e.detail.hasOwnProperty(ucAdService)){gtag("consent","update",{ad_storage:e.detail[ucAdService]?"granted":"denied",ad_user_data:e.detail[ucAdService]?"granted":"denied",ad_personalization:e.detail[ucAdService]?"granted":"denied",analytics_storage:e.detail[ucAnalyticsService]?"granted":"denied"})}else{if(e.detail.hasOwnProperty(ucAdService)){gtag("consent","update",{ad_storage:e.detail[ucAdService]?"granted":"denied",ad_user_data:e.detail[ucAdService]?"granted":"denied",ad_personalization:e.detail[ucAdService]?"granted":"denied"})}if(e.detail.hasOwnProperty(ucAnalyticsService)){gtag("consent","update",{analytics_storage:e.detail[ucAnalyticsService]?"granted":"denied"})}}}}));`,
        // 3. Google Tag Manager
        `(function(t,e,a,m,g){t[m]=t[m]||[];t[m].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var n=e.getElementsByTagName(a)[0],r=e.createElement(a),i=m!="dataLayer"?"&l="+m:"";r.async=true;r.src="${gtmDomain}/gtm.js?id="+g+i+"&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x";n.parentNode.insertBefore(r,n)})(window,document,"script","dataLayer","${gtmId}");`,
      ]
      scripts.forEach((script, index) => {
        const el = document.createElement('script')
        el.innerHTML = script
        document.head.insertBefore(el, document.head.childNodes[index])
      })
    }
  }, [gtmAuth, gtmDomain, gtmId, gtmPreview])

  return (
    <>
      <CloseLanguageSwitchOnNavigation onNavigation={closeLanguageSwitch} />

      {lastRequestStatus !== 503 && lastRequestStatus >= 500 ? (
        <GenericErrorPage code={lastRequestStatus} shouldDisableLink />
      ) : (
        isChecked && (
          <>
            <Head
              pageTitle={t('default:PageTitle')}
              htmlLang={i18n.language}
              description={
                window.location.pathname.includes('audio-library')
                  ? t('default:PageDescAudioLib')
                  : t('default:PageDescMyTonies')
              }
            />

            <Wrapper
              data-testid="spa-wrapper"
              isNewCreativeToniePdP={isCreativeTonieDetailPage}
            >
              <VersionUpdateNotification />
              {!window.location.href.includes('tell-a-story') && (
                <PromotionBanner />
              )}
              {!isWebview && showHeader && (
                <MyToniesHeader
                  idLanguageSwitch={idLanguageSwitch}
                  isEduUser={user?.isEduUser ?? false}
                  isLanguageSwitchOpen={isLanguageSwitchOpen}
                  isLoggedIn={isAuthenticated}
                  name={user?.firstName ?? ''}
                  onLogin={() => login({ shouldReplaceHistory: false })}
                  onLogout={() => logout()}
                  onToggleLanguageSwitch={wasTriggeredByKeyboard => {
                    wasTriggeredByKeyboardRef.current = wasTriggeredByKeyboard
                    setIsLanguageSwitchOpen(!isLanguageSwitchOpen)
                  }}
                />
              )}

              {isLanguageSwitchOpen ? (
                <LanguageSwitch
                  availableLanguages={supportedLanguages}
                  id={idLanguageSwitch}
                  onClose={() => setIsLanguageSwitchOpen(false)}
                  onSelectLanguage={changeLanguage}
                  shouldFocusFirstElement={wasTriggeredByKeyboardRef.current}
                />
              ) : (
                <Suspense fallback={<FeaturePreview />}>
                  <Switch>
                    <ProtectedRoute exact path="/" component={Home} />
                    <AnonymousRoute exact path="/login" component={Welcome} />

                    <AnonymousRoute
                      exact
                      path="/signup"
                      component={() => {
                        register()
                        return null
                      }}
                    />
                    <Redirect exact from="/accounts/signup/" to="/signup" />
                    <Redirect
                      exact
                      from="/accounts/password/reset"
                      to="/password/reset"
                    />
                    <ProtectedRoute
                      exact
                      path="/accounts/details"
                      component={UserProfile}
                    />
                    <Redirect exact from="/accounts/logout" to="/" />
                    <Redirect
                      exact
                      from="/audio-library"
                      to="/audio-library/my-purchases"
                    />
                    <ProtectedRoute
                      exact
                      path={[
                        '/audio-library/free-for-you',
                        '/audio-library/shop',
                        '/audio-library/my-purchases',
                      ].filter(Boolean)}
                      component={AudioLibraryPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/audio-library/:tunesItemId/assign"
                      component={AudioAssignTonie}
                    />
                    <ProtectedRoute
                      exact
                      path="/audio-library/:tunesItemId/:libraryParams?"
                      component={AudioLibraryDetailPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/audio-candy/:contentToken/assign"
                      component={AudioCandyAssign}
                    />
                    <ProtectedRoute
                      exact
                      path="/audio-candy/:contentToken/:libraryParams?"
                      component={AudioCandyDetailPage}
                    />
                    <Redirect
                      exact
                      from="/weihnachtswochen"
                      to="/creative-tonies"
                    />
                    <ProtectedRoute
                      exact
                      path="/ard-hoer-spiel-ferien"
                      component={ARDLandingPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/lp/:landingPageUrl"
                      component={FreeContentLandingPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/audio-library/:tunesItemId/payment/:purchaseId"
                      component={AudioLibraryPaymentPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/content-tonies"
                      component={ContentToniePage}
                    />
                    <ProtectedRoute
                      exact
                      path="/content-tonies/:householdId/:contentTonieId"
                      component={ContentTonieDetailPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/content-tonies/:householdId/:contentTonieId/edit"
                      component={ContentTonieEditPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/content-tonies/:householdId/:contentTonieId/refresh"
                      component={TonieRefreshPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/content-tonies/:householdId/:contentTonieId/reset"
                      component={TonieResetPage}
                    />
                    <Redirect exact from="/tonies" to="/creative-tonies" />
                    <ProtectedRoute
                      exact
                      path="/creative-tonies"
                      component={CreativeToniePage}
                    />
                    <ProtectedRoute
                      exact
                      path="/households"
                      component={Members}
                      allowEdu={false}
                    />
                    <ProtectedRoute
                      exact
                      path="/households/:householdId/member/:membershipId"
                      component={MemberDetails}
                      allowEdu={false}
                    />
                    <ProtectedRoute
                      exact
                      path="/households/:householdId/invitation/:invitationId"
                      component={InvitationDetails}
                      allowEdu={false}
                    />
                    <ProtectedRoute
                      exact
                      path="/tonieboxes"
                      component={TonieboxesPage}
                    />

                    {isLucidDreamEnabled && (
                      <ProtectedRoute
                        exact
                        path="/tell-a-story"
                        component={LucidDream}
                      />
                    )}
                    <Redirect
                      from="/tonies/:householdId/:creativeTonieId"
                      to="/creative-tonies/:householdId/:creativeTonieId"
                    />
                    {/* Redirect for the App which uses the old url to LucidDream */}
                    {isLucidDreamEnabled && (
                      <ProtectedRoute
                        exact
                        path="/creative-tonies/:householdId/:creativeTonieId/story-generator"
                        component={RedirectLucidDream}
                      />
                    )}
                    {/* Route for LudcidDream */}
                    {isLucidDreamEnabled && (
                      <ProtectedRoute
                        exact
                        path="/tell-a-story"
                        component={LucidDream}
                      />
                    )}
                    <ProtectedRoute
                      path="/creative-tonies/:householdId/:creativeTonieId"
                      component={CreativeTonieDetailPage}
                    />
                    <ProtectedRoute
                      path="/creative-tonies/:householdId/:creativeTonieId/refresh"
                      component={TonieRefreshPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/creative-tonies/:householdId/:creativeTonieId/reset"
                      component={TonieResetPage}
                    />
                    <ProtectedRoute
                      exact
                      path="/toniebox/:householdId/:tonieboxId"
                      component={TonieboxDetails}
                    />
                    <ProtectedRoute
                      path="/invitations/:token/accept"
                      fallbackPath="/login"
                      component={AcceptInvitation}
                      sensitive={true}
                    />

                    <RedirectExternal
                      exact
                      path="/data_regulations"
                      target="/legals/privacy-policy"
                    />
                    <RedirectExternal
                      exact
                      path="/terms_and_conditions"
                      target="/legals/terms-of-service"
                    />
                    <RedirectExternal
                      exact
                      path="/imprint"
                      target="/legals/imprint"
                    />

                    <Route exact path="/byebye" component={ByeBye} />
                    <Route exact path="/setup-toniebox" component={Setup} />
                    <RedirectExternal
                      exact
                      path="/cookie-settings"
                      target="https://tonies.com/?showCmp=1"
                    />
                    <Route
                      exact
                      path="/wifi"
                      render={({ location, ...props }) => {
                        return (
                          <Setup
                            {...props}
                            location={{
                              ...location,
                              state: {
                                ...location.state,
                                clearState: true,
                                wifiSetup: true,
                              },
                            }}
                          />
                        )
                      }}
                    />
                    <Route
                      exact
                      path="/setup/init_wifi"
                      render={({ location, ...props }) => {
                        return (
                          <Setup
                            {...props}
                            location={{
                              ...location,
                              pathname: '/wifi',
                              state: { wifiSetup: true, clearState: true },
                            }}
                          />
                        )
                      }}
                    />
                    <Route
                      path="/setup"
                      render={({ location, ...props }) => {
                        return (
                          <Setup
                            {...props}
                            location={{
                              ...location,
                              pathname: '/setup-toniebox',
                              state: { clearState: true },
                            }}
                          />
                        )
                      }}
                    />
                    <Route
                      exact
                      path="/password/reset"
                      render={() => {
                        resetPassword()

                        return null
                      }}
                    />
                    <Route
                      exact
                      path="/accounts/password/reset/key/:key"
                      render={() => {
                        resetPassword()

                        return null
                      }}
                    />
                    <Route path="/legals/:page" component={Legals} />
                    <Route path="/error/:code" component={GenericErrorPage} />
                    <Route component={NoMatch} />
                  </Switch>
                </Suspense>
              )}

              {!isWebview && showFooter && (
                <MyToniesFooter
                  idLanguageSwitch={idLanguageSwitch}
                  isLanguageSwitchOpen={isLanguageSwitchOpen}
                  onToggleLanguageSwitch={wasTriggeredByKeyboard => {
                    wasTriggeredByKeyboardRef.current = wasTriggeredByKeyboard
                    setIsLanguageSwitchOpen(!isLanguageSwitchOpen)
                  }}
                />
              )}
            </Wrapper>
            {isSupportWidgetEnabled &&
              !isWebview &&
              !location.pathname.startsWith('/legals') &&
              user && // Loading SupportWidget only when user is logged in
              showFooter && (
                <Aside
                  shouldRespectStickyOverlayOnNewCTPDP={
                    isCreativeTonieDetailPageAssignOrEditView
                  }
                >
                  <SupportWidget />
                </Aside>
              )}
            <ToastContainer />
          </>
        )
      )}
    </>
  )
}

export default Sentry.withProfiler(App)
