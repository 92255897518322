import {
  Accent,
  Box,
  FocusStyles,
  GeoIpRegions,
  Headline4,
  media,
  theme,
  toast,
  useEnvLink,
  useMedia,
} from '@boxine/tonies-ui'
import React, { lazy, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { useQuery } from 'urql'
import { Link } from 'react-router-dom'
import { DashboardCreativeTonies } from '../../components/dashboard-creative-tonies'
import { DashboardContentTonies } from '../../components/dashboard-creative-tonies/DashboardContentTonies'
import { DashboardEdu } from '../../components/dashboard-edu'
import { DashboardGreeting } from '../../components/dashboard-greeting'
import DashboardInvite from '../../components/dashboard-invite'
import { Head } from '../../components/head'
import {
  HomeBoxPreview,
  HomeCreativeTonieBoxPreview,
  HomeTonieboxesPreview,
} from '../../components/Skeletons/dashboard-box'
import { SystemNotifications } from '../../components/system-notifications'
import { useIsAudioLibraryAvailable } from '../../hooks/useIsAudioLibraryAvailable'
import { InnerContainer, LayoutV2 } from '../../layouts/LayoutV2'
import { DashboardQuery, query } from '../../queries/dashboard-query'
import { DiscoverMoreSlider } from '../../components/DiscoverMoreSlider/DiscoverMoreSlider'
import { AddTonieboxModal } from '../../components/AddTonieboxModal'
import {
  ResponsiveButton,
  ResponsiveLinkButton,
} from '../../components/visual-elements/responsive-button'
import Toniebox from '../../components/toniebox'
import { TuneCard } from '../../components/TuneCard'
import { useUserProfile } from '../../providers/user-profile'
import { ShopBox } from './ShopBox'
import { Teaser } from './Teaser'
import TonieSongBox from './AudioContentOrFreeContentBox'

const ThreeInvestigatorsVoucher = lazy(
  () =>
    /* webpackChunkName: "three-investigators-voucher" */ import(
      './ThreeInvestigatorsVoucher'
    )
)

const StyledLayout = styled(LayoutV2)`
  background: ${props => props.theme.GrayLightest};
  margin: 1rem 0;

  ::before {
    background-color: ${props => props.theme.GrayLightest};
  }
`

const StyledInnerContainer = styled(InnerContainer)`
  padding-left: 0;
  padding-right: 0;
`

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin-left: 1rem;
  margin-right: 1rem;

  ${media.tabletL`
    margin-left: 0;
    margin-right: 0;
  `}
`

const FullStyledBox = styled(StyledBox)`
  margin-left: 1rem;
  margin-right: 1rem;

  ${media.laptop`
    grid-column: span 2;
    margin-left: 0;
    margin-right: 0;
  `}
`

const FullArea = styled.div`
  min-width: 0; /* Prevent grid blowout */

  ${media.laptop`
    grid-column: span 2;
  `}
`

const StyledHeadline = styled(Headline4)`
  margin: 0.5rem 0;
  /* FIXME why do we have to specify this everywhere?? */
  em {
    color: ${props => props.theme.BrandPrimary};
  }
`

const TonieboxesWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  ${media.tabletL`
    grid-template-columns: repeat(2, 1fr);
  `};
`

const TonieboxLink = styled(Link)`
  text-decoration: none;
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-radius: 0.1875rem;
  img {
    transition: 0.2s transform ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.12);
    }
  }
  ${FocusStyles};
`

export default function Home() {
  const location = useLocation()
  const { canBuyTunes, canGetAudioCandy } = useIsAudioLibraryAvailable()
  const { t } = useTranslation(['default', 'home'])
  const { user } = useUserProfile()
  const isLaptopWidth = useMedia({ minWidth: theme.screenLaptop })
  const [addTonieboxModalOpen, setAddTonieboxModalOpen] = useState(false)
  const [{ data, fetching, error }, refresh] = useQuery<DashboardQuery>({
    query,
    variables: {
      canBuyTunes,
      canGetAudioCandy,
    },
    requestPolicy: 'network-only',
  })
  const isThreeInvestigators = useMemo(
    () => location.search.startsWith('???'),
    [location.search]
  )
  const { region } = useUserProfile()
  const shopLink = useEnvLink(
    'websiteLink',
    // we don't run the shop locally, so link to dev instead
    process.env.REACT_APP_ENVIRONMENT === 'local'
      ? 'dev'
      : process.env.REACT_APP_ENVIRONMENT,
    // TODO make our Regions type and this compatible (uppercase everything)
    region.toUpperCase() as GeoIpRegions
  )

  // Switch to voucher input for ???
  if (isThreeInvestigators && user) {
    return <ThreeInvestigatorsVoucher firstName={user.firstName} />
  }

  if (error) {
    toast(t('default:TOASTSomethingWentWrong'), 'error', {
      toastId: 'something-went-wrong',
    })
  }

  const searchParams = new URLSearchParams(location.search)

  if (searchParams.has('invitation_success')) {
    toast(t('default:TOASTAcceptInvitationSuccess'), 'success', {
      toastId: 'accept-invitation-success',
    })
  }

  if (fetching || !user) {
    return (
      <StyledLayout>
        <StyledInnerContainer>
          <HomeCreativeTonieBoxPreview />
          <HomeCreativeTonieBoxPreview />
          <HomeTonieboxesPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
          <HomeBoxPreview />
        </StyledInnerContainer>
      </StyledLayout>
    )
  }

  if (!data) return null

  const { contentTonies, creativeTonies, tonieboxes, tunesItems } =
    data.frontpage
  const { households, contentTokens: contentTokenCollection } = data
  const contentTokens =
    canGetAudioCandy && contentTokenCollection
      ? contentTokenCollection.edges.map(({ node }) => node)
      : undefined

  return (
    <>
      <StyledLayout>
        <StyledInnerContainer>
          <Head pageTitle={t('home:PageTitle')} />
          <div className="container">
            <SystemNotifications />
          </div>
          <DashboardGreeting firstName={user.firstName} />
          <Teaser
            tonieboxes={tonieboxes || []}
            showAddTonieboxModal={() => setAddTonieboxModalOpen(true)}
          />
          <div className="grid container">
            {contentTonies && (
              <FullStyledBox
                backgroundColor={theme.colors.white}
                p="spacing-s"
                borderRadius={['xs']}
                mx={!isLaptopWidth ? 'spacing-s' : undefined}
              >
                <DashboardContentTonies contentTonies={contentTonies} />
              </FullStyledBox>
            )}
            {canBuyTunes && tunesItems && (
              <FullArea>
                {/* TODO
                Add "real" localized Shop Audio Content link
                using the Shop's main categories JSON */}
                <DiscoverMoreSlider
                  data-testid="tunes-home-slider"
                  data-trackingid="home__tunes-cover-slider__find-more-click"
                  findMoreHref={`${shopLink}/audio-content`}
                  headline={t('home:TunesHeading')}
                  hasNoBackgroundColor
                  padding="1.5rem 0 2rem 0"
                  shouldShowPrices
                >
                  {tunesItems.map(tune => {
                    return (
                      <TuneCard
                        key={tune.id}
                        tune={tune}
                        data-trackingid="home__tunes-cover-slider__cover-click"
                      />
                    )
                  })}
                </DiscoverMoreSlider>
              </FullArea>
            )}
            {creativeTonies && (
              <FullStyledBox
                backgroundColor={theme.colors.white}
                p="spacing-s"
                borderRadius={['xs']}
                mx={!isLaptopWidth ? 'spacing-s' : undefined}
              >
                <DashboardCreativeTonies creativeTonies={creativeTonies} />
              </FullStyledBox>
            )}
            {contentTokens && (
              <FullArea>
                <DiscoverMoreSlider
                  data-testid="audio-candy-home-slider"
                  data-trackingid="home__free-content-cover-slider__find-more-click"
                  findMoreHref="/audio-library/free-for-you"
                  headline={t('home:AudioCandyHeading')}
                  hasNoBackgroundColor
                  padding="1.5rem 0 2rem 0"
                  shouldShowPrices
                >
                  {contentTokens.map(contentToken => {
                    return (
                      <TuneCard
                        key={contentToken.id}
                        to={`/audio-candy/${contentToken.token}/free-content?relatedUrl=/`}
                        data-trackingid={`home__free-content-cover-slider__cover-click-[${contentToken.token}`}
                        tune={contentToken}
                      />
                    )
                  })}
                </DiscoverMoreSlider>
              </FullArea>
            )}
            <StyledBox
              backgroundColor={theme.colors.white}
              p="spacing-s"
              borderRadius={['xs']}
              mx={!isLaptopWidth ? 'spacing-s' : undefined}
            >
              {!tonieboxes || tonieboxes.length === 0 ? (
                <>
                  <Trans i18nKey="home:MessageNoToniebox">
                    <StyledHeadline
                      weight={500}
                      data-testid="dashboard-no-toniebox-headline"
                    >
                      Du hast zurzeit noch keine <Accent>Toniebox</Accent>.
                      Möchtest du eine hinzufügen?
                    </StyledHeadline>
                  </Trans>
                  <ResponsiveButton
                    dataTestId="dashboard-no-toniebox-add-toniebox-button"
                    styling="primary"
                    onClick={() => setAddTonieboxModalOpen(true)}
                  >
                    {t('home:AddToniebox')}
                  </ResponsiveButton>
                </>
              ) : (
                <>
                  <Trans
                    i18nKey="home:YourToniebox"
                    count={tonieboxes?.length || 0}
                  >
                    <StyledHeadline
                      weight={500}
                      data-testid="dashboard-tonieboxes-headline"
                      asHTMLTag="h2"
                    >
                      Deine <Accent>Toniebox</Accent>
                    </StyledHeadline>
                  </Trans>
                  <TonieboxesWrapper>
                    {tonieboxes?.map(toniebox => {
                      const imageUrl = toniebox.imageUrl || ''
                      return (
                        <TonieboxLink
                          to={`/toniebox/${toniebox.householdId}/${toniebox.id}`}
                          key={toniebox.id}
                          data-testid="dashboard-tonieboxes-link"
                        >
                          <Toniebox
                            src={imageUrl}
                            name={toniebox.name}
                            alt={toniebox.name}
                          />
                        </TonieboxLink>
                      )
                    })}
                  </TonieboxesWrapper>
                  <ResponsiveLinkButton
                    styling="primary"
                    href="/tonieboxes"
                    data-trackingid="home__to-tonieboxes__button-press"
                  >
                    {t('home:AllTonieboxes')}
                  </ResponsiveLinkButton>
                </>
              )}
            </StyledBox>
            <Box
              backgroundColor={theme.colors.white}
              p="spacing-s"
              borderRadius={['xs']}
              mx={!isLaptopWidth ? 'spacing-s' : undefined}
            >
              {user.isEduUser ? (
                <DashboardEdu />
              ) : (
                <DashboardInvite households={households} />
              )}
            </Box>
            <ShopBox />
            <TonieSongBox />
          </div>
        </StyledInnerContainer>
      </StyledLayout>
      <AddTonieboxModal
        isOpen={addTonieboxModalOpen}
        onClose={() => setAddTonieboxModalOpen(false)}
        onSuccess={() => {
          refresh()
          setAddTonieboxModalOpen(false)
        }}
        households={households}
        trackingidPrefix="home"
      />
    </>
  )
}
